export const translationHu = {
  header: {
    menu: {
      backToYettel: 'Vissza a Yettel.hu-ra',
      yettelAccount: 'Yettel fiók',
    },
  },
  loginRegister: {
    login: {
      failedToInit: {
        header: 'Hoppá!<br />Valami hiba történt',
        content: 'Valami miatt nem tudjuk betölteni a felületet, dolgozunk a megoldáson. Kérjük próbáld újra később.',
        action: 'Újra próbálom',
      },
      loginError: {
        header: 'Hoppá!<br />Valami hiba történt',
        content: 'A bejelentkezés során valami nem sikerült, dolgozunk a megoldáson. Kérjük próbáld újra később.',
        action: 'Újra próbálom',
      },
      box: {
        header: 'Yettel fiók belépés',
        headerSMS: 'SMS belépés',
        email: 'E-mail cím',
        emailTooltip: 'A korábban megadott e-mail címeddel tudsz belépni.',
        password: 'Jelszó',
        login: 'Belépek',
        otherAuthHeader: 'vagy',
        oauthGoogle: 'Belépés Google fiókkal',
        oauthFacebook: 'Belépés Facebook fiókkal',
        oauthApple: 'Belépés Apple fiókkal',
        authSMS: 'Belépés SMS kóddal',
        forgottenPassword: 'Elfelejtetted a jelszavad?',
        oauthError: {
          header: (provider: string) => `Hoppá!<br />Úgy látszik nincs ${provider} fiók csatolva a profilodhoz.`,
          content: (provider: string) =>
            `Még nem csatoltál yettel fiókot ehhez a ${provider} profilhoz, miután bejelentkeztél e-mail cím jelszóval hozzá társíthatod a fiókodhoz.`,
          action: `Bejelentkezem máshogy`, // ez mi? Dorka
        },
        loginError: {
          invalidEmail: `Érvénytelen e-mail cím.`,
          invalidPassword: `Jelszó megadása kötelező.`,
          invalidUserNameOrPassword: `Hibás e-mail cím, vagy jelszó.`,
          sessionTimeout: `A bejelentkezési munkamenet lejárt, jelentkezz be újra.`,
          general: `A bejelentkezés sikertelen. Kérjük próbáld újra később.`,
          invalidCaptcha: `A captcha ellenőrzés sikertelen. Kérjük próbáld újra!`,
          invalidCode: `Hibás kód.`,
          subscriptionCheckError: (link: string) =>
            `SMS kóddal nem tudsz belépni. <a href="${link}">Nézd meg</a> mi lehet a hiba.`,
        },
        register: 'Regisztrálok',
        business: {
          title: 'Üzleti ügyfelünk vagy?',
          content: (link: string) =>
            `<a href="${link}">Kattints ide</a>, és átirányítunk az üzleti belépési felületre.`,
        },
      },
      phone: {
        phoneNumber: `Add meg a számot, amire az SMS-t küldjük.`,
        next: `Kérem az SMS kódot`,
        back: `Vissza`,
        code: `Add meg az SMS kódot`,
        sendAgain: `Nem kaptam meg az SMS kódot, újat kérek`,
        login: `Belépek`,
      },
      accountLocked: {
        header: `Biztonságod érdekében javasoljuk, hogy cseréld le jelszavad`,
        content: (_videochatLink: string) =>
          `A fiókodban tapasztalt gyanús tevékenységek arra utalnak, hogy illetéktelenek próbálnak hozzáférni adataidhoz.
	   <br />Biztonságod érdekében változtass jelszót.<br />Ha nem szeretnéd jelszavadat megváltoztatni, akkor 
	   várd meg, amíg kollégáink feloldják a fiókod zárolását.`,
        sendConfirmEmail: `Jelszót változtatok`,
        cancel: 'Nem változtatok jelszót',
      },
      updatePassword: {
        header: `Biztonságod érdekében javasoljuk, hogy cseréld le jelszavad`,
        content: `A fiókodban tapasztalt gyanús tevékenységek arra utalnak, hogy illetéktelenek próbálnak hozzáférni adataidhoz.
	   Biztonságod érdekében <br/>&nbsp;<br/>változtass jelszót.<br />Ha nem szeretnéd jelszavadat megváltoztatni, akkor 
	   várd meg, amíg kollégáink feloldják a fiókod zárolását.`,
        sendConfirmEmail: `Jelszót változtatok`,
        ignorePasswordChange: `Nem változtatok jelszót`,
      },
      mfa: {
        authenticator: {
          header: `Add meg az ellenőrző kódot`,
          subHeader: `Írd be a 6 számjegyű kódodat az Autentikátorodból.`,
          inputLabel: `Ellenőrző kód`,
          login: `Belépek`,
        },
        sms: {
          header: `Add meg az ellenőrző kódot`,
          subHeader: (phoneNumber: string) =>
            `Az ellenőrző kódot elküldtük (SMS-ben) a ${phoneNumber} végű telefonszámodra. Ellenőrizd az üzeneteid, majd add meg a kódot.`,
          inputLabel: `Ellenőrző kód`,
          login: `Belépek`,
          backToPhoneNumber: `Vissza a telefonszám megadáshoz`,
        },
      },
    },
    register: {
      box: {
        header: 'Yettel fiók regisztráció',
        name: 'Mi a neved?',
        email: 'Add meg az e-mail címedet',
        register: 'Regisztráció',
        oauthSectionLabel: 'vagy',
        oauthGoogle: 'Regisztráció Google fiókkal',
        oauthFacebook: 'Regisztráció Facebook fiókkal',
        error: 'A fiók regisztráció sikertelen. Kérjük próbáld újra később',
      },
      hero: {
        header: 'Intézd ügyeidet online Yettel fiókkal!',
        desc: (link: string | undefined) =>
          `Nincs még fiókod? Regisztrálj pár kattintással${link ? '. Ha elakadtál, kérj segítséget <a href="' + link + '">chat</a>-en.' : ' és élvezd a yettel előnyöket.'}`,
      },
      result: {
        header: 'Yettel fiók regisztráció',
        subHeader: 'Az e-mailt elküldtük',
        desc: 'A megadott e-mail címedre elküldtük az aktiváló linket tartalmazó e-mail-t. Kérjük, ellenőrizd postafiókodat és véglegesítsd regisztrációdat, erre 24 óra áll rendelkezésedre, utána újra kell kezdened a regisztrációs folyamatot.',
        resend: 'E-mail újra küldése',
        backToYettelAccount: 'Vissza a Yettel fiókhoz',
      },
    },
    otp: {
      title: 'Küldtünk egy e-mailt az aktiváló kódoddal. Kérlek add meg a kapott kódot.',
      sendAgain: 'Újra küldés',
      invalidCode: 'A megadott aktiváló kód nem megfelelő',
    },
  },
};
export const translationEn: typeof translationHu = {
  header: {
    menu: {
      backToYettel: 'Back to Yettel.hu',
      yettelAccount: 'Yettel account',
    },
  },
  loginRegister: {
    login: {
      failedToInit: {
        header: 'Error',
        content: 'Failed to initialize login interface. Please try again later.',
        action: 'Try again',
      },
      loginError: {
        header: 'Error',
        content: 'Failed to login. Please try again later.',
        action: 'Try again',
      },
      box: {
        header: 'Yettel account login',
        headerSMS: 'Yettel account SMS login',
        email: 'E-mail address / Username',
        emailTooltip: 'You can login with the e-mail address you provided earlier.',
        password: 'Password',
        login: 'Login',
        otherAuthHeader: 'or',
        oauthGoogle: 'Login with Google',
        oauthFacebook: 'Login with Facebook',
        oauthApple: 'Login with Apple',
        authSMS: 'Login with SMS',
        forgottenPassword: 'Forgot your password?',
        oauthError: {
          header: (provider: string) => `Ups! It seems that you don't connection ${provider} account to your profile.`,
          content: (provider: string) =>
            `You haven't connected ${provider} account to your profile. You can connect it after you login with e-mail and password.`,
          action: `Login with e-mail and password`,
        },
        loginError: {
          invalidEmail: `Invalid e-mail address.`,
          invalidPassword: `Password is required.`,
          invalidUserNameOrPassword: `Wrong e-mail address or password`,
          sessionTimeout: `Your login session has expired, please login again.`,
          general: `Login failed. Please try again later.`,
          invalidCaptcha: `Captcha verification failed. Please try again!`,
          invalidCode: `Invalid code.`,
          subscriptionCheckError: (link: string) =>
            `You can't log in with this number using SMS. <a href="${link}">Check out</a> what might be the problem!`,
        },
        register: 'Register',
        business: {
          title: 'Are you a business customer?',
          content: (link: string) =>
            `Due to the background development, you can't log in here. <a href="${link}">Click here</a> to login.`,
        },
      },
      phone: {
        phoneNumber: `Enter the phone number you want to login with`,
        next: `Send me the SMS code`,
        back: 'Back to login',
        code: `Enter the SMS code`,
        sendAgain: `I didn't receive the SMS code, send me another one`,
        login: `Login`,
      },
      accountLocked: {
        header: `For your security, we recommend that you change your password.`,
        content: (videochatLink: string) =>
          `The suspicious activity on your account indicates that an unauthorized person has tried to access your account.<br/>&nbsp;<br/>Change your password to protect your account or wait until our colleagues unlock your account or contact us via <a class="button button--text button--no-left-right-padding" href="${videochatLink}">videochat</a>.`,
        sendConfirmEmail: `Change password`,
        cancel: "I don't want to change my password",
      },
      updatePassword: {
        header: `For your security, we recommend that you change your password.`,
        content: `The suspicious activity on your account indicates that an unauthorized person has tried to access your account. Change your password to protect your account.`,
        sendConfirmEmail: `Send confirmation e-mail`,
        ignorePasswordChange: `I don't want to change my password`,
      },
      mfa: {
        authenticator: {
          header: `Enter the verification code`,
          subHeader: `Enter the 6-digit code from your google authenticator.`,
          inputLabel: `Verification code`,
          login: `Login`,
        },
        sms: {
          header: `Enter the verification code`,
          subHeader: (phoneNumber: string) =>
            `We have sent the verification code (SMS) to the phone number ending with ${phoneNumber}. Check your messages and enter the code.`,
          inputLabel: `Verification code`,
          login: `Login`,
          backToPhoneNumber: `back to phone number`,
        },
      },
    },
    register: {
      box: {
        header: 'Yettel account registration',
        name: 'What is your name?',
        email: 'Enter your e-mail address',
        register: 'Register',
        oauthSectionLabel: 'or',
        oauthGoogle: 'Register with Google account',
        oauthFacebook: 'Register with Facebook account',
        error: 'Failed to create account. Please try again later.',
      },
      hero: {
        header: 'Manage your subscription online with Yettel account!',
        desc: (link: string | undefined) =>
          `Don't have an account yet? Register a new account with a few clicks. ${link ? 'If you are stuck, ask for help via <a href="' + link + '">chat</a>' : ''}.`,
      },
      result: {
        header: 'Yettel account registration',
        subHeader: 'The e-mail has been sent',
        desc: 'Please check your e-mail address and follow the instructions in it.',
        resend: 'Resend e-mail',
        backToYettelAccount: 'Back to Yettel account',
      },
    },
    otp: {
      title: 'We have sent an e-mail with your activation code. Please enter the received code.',
      sendAgain: 'Resend',
      invalidCode: 'The entered activation code is not valid',
    },
  },
};
